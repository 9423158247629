import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const IconLogo = () => (
  <StaticImage
    id="logo"
    quality={95}
    className="img"
    alt="Briantical Logo"
    formats={['AUTO', 'WEBP', 'AVIF']}
    src="../../images/logo_alternate.png"
  />
);

export default IconLogo;
